import classNames from 'classnames';
import { orderBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import type { Member } from 'types/api';
import styles from './MemberList.module.scss';
import { memberAge } from 'shared/utils/dateHelpers';

const MemberList = ({
  members,
  onClickMember,
  filterString,
}: {
  members: Member[];
  filterString: string;
  onClickMember: (member: Member) => void;
}) => {
  const aliveMembers = useMemo(
    () => members.filter(m => m.date_of_death == null),
    [members],
  );
  const filteredMembers = useMemo(() => {
    if (filterString.length < 1) {
      return aliveMembers;
    }
    return aliveMembers.filter(member =>
      member.name
        .toLocaleLowerCase()
        .includes(filterString.toLocaleLowerCase()),
    );
  }, [aliveMembers, filterString]);

  type SortColumn = 'name' | 'date_of_birth' | 'email';
  const [sortColumn, setSortColumn] = useState<SortColumn>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const makeOnClickColumnHeader = (column: SortColumn) => () => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };
  const decorateColumnHeader = (column: SortColumn) => {
    if (sortColumn === column) {
      return sortDirection === 'asc' ? ' ▲' : ' ▼';
    }
  };

  const sortedMembers = useMemo(
    () => orderBy(filteredMembers, m => m[sortColumn] ?? '', [sortDirection]),
    [filteredMembers, sortColumn, sortDirection],
  );

  return (
    <div className={styles.root}>
      <table className={styles.list}>
        <thead>
          <tr>
            <th
              className={styles.sortable}
              onClick={makeOnClickColumnHeader('name')}
            >
              Namn
              {decorateColumnHeader('name')}
            </th>
            <th>Betalande medlem</th>
            <th
              className={styles.sortable}
              onClick={makeOnClickColumnHeader('date_of_birth')}
            >
              Födelsedatum
              {decorateColumnHeader('date_of_birth')}
            </th>
            <th
              className={styles.sortable}
              onClick={makeOnClickColumnHeader('email')}
            >
              Email
              {decorateColumnHeader('email')}
            </th>
            <th className={styles.hideOnMobile} />
          </tr>
        </thead>
        <tbody>
          {sortedMembers.map(member => (
            <tr key={member.id} className={styles.row}>
              <td className={styles.name} onClick={() => onClickMember(member)}>
                {member.name}
              </td>
              <td className={styles.centered}>
                {member.is_paying && <i className="fa fa-check" />}
              </td>
              <td>
                {member.date_of_birth} ({memberAge(member)} år)
              </td>
              <td>{member.email}</td>
              <td
                className={classNames(
                  styles.revealOnHover,
                  styles.hideOnMobile,
                )}
              >
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={() => onClickMember(member)}
                >
                  Ändra
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MemberList;
